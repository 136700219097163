import React, { lazy, Suspense } from "react";

import ActivityIndicator from "./Components/ActivityIndicator/ActivityIndicator";

const AppRouter = lazy(() => import("./Config/router"));

const App = () => {
  return (
    <Suspense
      fallback={
        <div className="box-center">
          <ActivityIndicator />
        </div>
      }
    >
      <AppRouter />
    </Suspense>
  );
};

export default App;
